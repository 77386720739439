export const CATEGORIES = [
    {
        id:0,
        productId:0,
        type: "Valve and parts"
    },
    {
        id:1,
        productId:1,
        type: "Valve and parts"
    },
    {
        id:2,
        productId:2,
        type: "Valve and parts" 
    },
    {
        id:3,
        productId:3,
        type: "Valve and parts"
    },
    {
        id:4,
        productId:4,
        type: "Valve and parts"
    },
    {
        id:5,
        productId:5,
        type: "Valve and parts"
    },
    {
        id:6,
        productId:6,
        type: "Valve and parts"
    },
    {
        id:7,
        productId:7,
        type: "Valve and parts"
    },
    {
        id:8,
        productId:8,
        type: "Valve and parts"
    },
    {
        id:9,
        productId:9,
        type: "Actuator Parts"
    },
    {
        id:10,
        productId:10,
        type: "Actuator Parts"
    },
    {
        id:11,
        productId:11,
        type: "Actuator parts"
    },
    {
        id:12,
        productId:12,
        type: "Regulator Parts"
    },
    {
        id:13,
        productId:13,
        type: "Regulator Parts"
    },
    {
        id:14,
        productId:14,
        type: "Regulator Parts"
    },
    {
        id:15,
        productId:15,
        type: "Instrument Parts"
    },
    {
        id:16,
        productId:16,
        type: "Instrument Parts"
    },
    {
        id:17,
        productId:17,
        type: "Instrument Parts"
    },
    
];