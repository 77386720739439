import React from 'react';
import { Jumbotron } from 'reactstrap';


const HomeService = ()=>{
    return(
        <Jumbotron className="jumbo">
             <div className="container">
                <div className="row dark-overlay">
                    <div className="col">
                        <div className="container mt-0">
                        <h1 className="text-center">We Serve You Better</h1>
                        <p className="d-none d-md-block text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                            distinctio dignissimos error veritatis
                            vitae et aperiam ex aliquid, sint fugit, expedita eos quibusdam placeat optio similique.
                            Pariatur animi ipsam autem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                            distinctio dignissimos error veritatis
                            vitae et aperiam ex aliquid</p>
                        </div>
                    </div>
                </div>
            </div>
        </Jumbotron>
               
        

    )
}
        
export default HomeService;       